<template>
  <div class="factory-block">
    <div class="factory-top flex">
      <div class="ft1 flex">
        <div @click="viewZizhi(item.id)" class="factory-logo flex flex-center">
          <img v-if="item.logo" :src="IMG_URL_PRE+item.logo">
          <i v-else class="iconfont icon-shengchanqiye1x"></i>
        </div>
        <div class="ft1-right flex-1">
          <div @click="viewZizhi(item.id)" class="name ellipsis"><b>{{item.name}}</b></div>
          <div class="btn flex flex-align-center">
            <el-button @click="get_phone()" size="mini" type="primary">联系方式</el-button>
            <div class="icon-view">
              <!-- <i class="iconfont icon-liulanbeifen4"></i><span>222</span> -->
            </div>
            <el-button v-if="comp.comp_type == 3" @click="goto('/want/wantIssue?type=gaikuang')" style="margin-left:20px;" size="mini" type="primary" plain>找TA定制</el-button>
            <el-button @click="viewZizhi(item.id)" style="margin-left:20px;" size="mini" type="primary">资质详情</el-button>
          </div>
          <div class="tit-wrap">
            <div v-if="item.aptitude && item.aptitude.ability_service">
              <!-- <color-label class="tag"
                v-for="(n, i) in item.aptitude.ability_service.range"
                :key="i"
                :text="n.v"/> -->
                <span class="tag"
                  v-for="(n, i) in item.aptitude.ability_service.range"
                  :key="i">{{n.v}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="ft2">
        <div class="name ellipsis"><b>公司简介</b></div>
        <div v-if="item.aptitude && item.aptitude.stat" class="ellipsis6">{{item.aptitude.stat.intro}}</div>
        <div v-else>暂无简介</div>
      </div>
      <div class="ft3 flex-1 flex flex-center">
        <div class="sale_percent">
          <div class="percent_progress">
            <el-progress
              type="circle"
              :percentage="item.aptitude_score"
              :width="100"
              :stroke-width="8"
              color="#FD8895"
            ></el-progress>
          </div>
          <div>资质完善度</div>
        </div>
      </div>
      <div class="ft4 flex-1 flex flex-center">
        <div>
          <div class="top">
            <div class="text">版权出售作品</div>
            <div class="num">{{item.nProductCopyRight}}</div>
          </div>
          <div class="bottom">
            <div class="text">分成合作作品</div>
            <div class="num" style="margin-bottom: 0;">{{item.nProductPercent}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="item.aptitude" class="factory-btm flex">
      <div class="name ellipsis"><b>历史案例</b></div>
      <div class="img-list">
        <div v-if="item.aptitude && item.aptitude.case" class="flex">
          <div class="list-item" v-for="(c,idx) in item.aptitude.case" :key="idx">
            <ImgView :list="item.aptitude.case" :idx="idx" width="140" height="140"></ImgView>
            
            <div class="text ellipsis">{{c.name}}</div>
          </div>
        </div>
        <div class="flex flex-center" style="width:100%;height:100%" v-else>设计公司很神秘，没有留下一点痕迹</div>
      </div>
    </div>

    <el-dialog :title="popTitle" :visible.sync="popVisible" width="500px" :destroy-on-close="true">
        <ItemPhone v-if="popTitle=='联系方式'" :type="5" :compId="item.id" @hide="popVisible=false"></ItemPhone>
    </el-dialog>

  </div>
</template>

<script>
import { IMG_URL_PRE } from "@/config";
// import ColorLabel from "@/components/factory/ColorLabel.vue";
import ItemPhone from '@/components/comp/ItemPhone.vue';
import ImgView from '@/components/comp/ImgView.vue';
export default {
  components: { ItemPhone,ImgView },
  props: {
    item: {
      type: Object
    },
  },
  data() {
    return {
      IMG_URL_PRE,
      popTitle: null,
      popVisible: false,
      colorArr: ["#97AFE2", "#E1A5D7", "#E6F182", "#A5DFCC"],
      employeeArr: [
        {
          name: "1-20人",
          value: 1
        },
        {
          name: "21-50人",
          value: 2
        },
        {
          name: "51-100人",
          value: 3
        },
        {
          name: "101-200人",
          value: 4
        },
        {
          name: "201-500人",
          value: 5
        },
        {
          name: "501-1000人",
          value: 6
        },
        {
          name: "1001人以上",
          value: 7
        }
      ],
      comp:null,
      index: [],
      translateX: [],
    };
  },
  computed: {
    jgsx() {
      // if (this.item.aptitude.ability_service.jgsx.length > 3) {
      //   return this.item.aptitude.ability_service.jgsx.slice(3);
      // }
      return this.item.aptitude.ability_service.jgsx;
    },
    mczl() {
      // if (this.item.aptitude.ability_service.mczl.length > 3) {
      //   return this.item.aptitude.ability_service.mczl.slice(3);
      // }
      return this.item.aptitude.ability_service.mczl;
    },
    wx() {
      // if (this.item.aptitude.ability_service.wx.length > 3) {
      //   return this.item.aptitude.ability_service.wx.slice(3);
      // }
      return this.item.aptitude.ability_service.wx;
    },
    zdcp() {
      // if (this.item.aptitude.ability_service.zdcp.length > 3) {
      //   return this.item.aptitude.ability_service.zdcp.slice(3);
      // }
      return this.item.aptitude.ability_service.zdcp;
    },
    gynl() {
      return this.item.aptitude.ability_make
        ? this.item.aptitude.ability_make.map(n => n.name).join("、")
        : "无";
    },
    // zone() {
    //   if (this.zoneList && this.zoneList.length>0) {
    //     return this.zoneList[this.item.aptitude.stat.zone].name;
    //   } else 
    //   return "无";
    // }
  },
  created() {
    this.comp = this.$store.state.loginUser.comp;
    if (this.item.aptitude && this.item.aptitude.case && this.item.aptitude.case.length>0) {
      this.index = Array(this.item.aptitude.case.length).fill(0);
      this.translateX = Array(this.item.aptitude.case.length).fill(0);
    }
  },
  mounted() {},
  methods: {
    goto(path) {
      this.$router.push(path);
    },
    // goSearch() {
    //   this.$router.push('/sale/mall/home/factory?search='+item.name);
    // },
    get_phone() {
        this.popTitle = "联系方式";
        this.popVisible = true;
    },
    viewZizhi(id) {
        window.open('/zizhi/view/'+id);
    },
    prev(i) {
      if (this.index[i] === 0) {
        return;
      }
      this.index[i]--;
      const wrap = document.querySelector(".img-wrap-1");
      const W = wrap.offsetWidth;
      this.translateX[i] += W;
      this.$forceUpdate();
    },
    next(i) {
      if (this.index[i] === this.item.aptitude.case[i].photo.length - 1) {
        return;
      }
      this.index[i]++;
      const wrap = document.querySelector(".img-wrap-1");
      const W = wrap.offsetWidth;
      this.translateX[i] -= W;
      this.$forceUpdate();
    }
  }
};
</script>
<style src="../style.css"></style>
<style scoped>
.hide {
  visibility: hidden;
}
.ant-carousel >>> .slick-slide {
  height: 228px;
}

.ant-carousel >>> .custom-slick-arrow i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 16px;
  color: #ccc;
  background: #fff;
  border-radius: 50%;
  opacity: 0.8;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}
</style>
<style lang="less" scoped>
.ellipsis6 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
}
.factory-block {
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
  // border-radius: 4px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .factory-top {
    padding-bottom: 20px;
    .ft1 {
      width: 470px;
      padding-right: 20px;
      border-right: 1px solid #E6E6E6;
      .factory-logo {
        width: 48px;
        height: 48px;
        background: #EDF1FF;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 12px;
        cursor: pointer;
        i {
          font-size: 28px;
          color: #5074EE;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
      .ft1-right {
        .name {
          font-size: 18px;
          color: #292929;
          line-height: 48px;
          margin-bottom: 22px;
          cursor: pointer;
        }
        .btn {
          margin-bottom: 16px;
          .icon-view {
            padding: 0 20px 0 12px;
            font-size: 14px;
            color: #4D4D4D;
            height: 28px;
            line-height: 28px;
            border-right: 1px solid #E6E6E6;
            i {
              font-size: 14px;
              color: #808080;
              margin-right: 3px;
            }
          }
        }
        .tit-wrap {
          .tag {
            display: inline-block;
            vertical-align: top;
            padding: 4px 10px;
            background: #FAFAFA;
            border: 1px solid #E6E6E6;
            border-radius: 15px;
            font-size: 12px;
            color: #4D4D4D;
            line-height: 16px;
            margin: 0 12px 8px 0;
          }
        }
      }
    }
    .ft2 {
      width: 300px;
      padding: 0 20px;
      border-right: 1px solid #E6E6E6;
      overflow-wrap: break-word;
      line-height: 20px;
      .name {
        font-size: 16px;
        color: #292929;
        line-height: 48px;
      }
    }
    .ft3 {
      text-align: center;
      font-size: 14px;
      color: #808080;
      border-right: 1px solid #DBE1F6;
    }
    .ft4 {
      .text {
        font-size: 14px;
        color: #808080;
        line-height: 20px;
        margin-bottom: 10px;
      }
      .num {
        font-size: 24px;
        color: #5074EE;
        line-height: 32px;
        margin-bottom: 16px;
      }
    }
    
  }
  .factory-btm {
    padding: 20px;
    background: #e6ebfa30;
    .name {
      font-size: 18px;
      color: #292929;
      line-height: 20px;
      margin-bottom: 12px;
    }
    .img-list {
      width: 100%;
      min-height: 156px;
      .list-item {
        width: 140px;
        margin-right: 30px;
        .text {
          font-size: 14px;
          color: #292929;
          line-height: 24px;
        }
      }
      
    }
  }
}
</style>
